.booking-form{

  &__content{
    max-width: 935px;
    margin: 0 auto;
    font-family: $menu-font;
  }

  &__title,
  &__title-2{
    text-align: center;
  }

  &__title{
    font-size: 40px;
    font-weight: 700;
    color: $brand-color;
    margin-bottom: 5px;

    @include max-w($screen-lg){
      font-size: 35px;
    }

    @include max-w($screen-md){
      font-size: 30px;
    }

    @include max-w($screen-sm) {
      font-size: 20px;
    }
  }

  &__title-2{
    font-family: $title-font;
    text-transform: uppercase;
    font-size: 28px;
    color: $dark-color;
    letter-spacing: 0.78px;
    line-height: 64.55px;

    @include max-w($screen-lg){
      line-height: 34px;
    }

    @include max-w($screen-md){
      font-size: 20px;
      line-height: 25px;
    }
  }
}

.radio-buttons{
  max-width: 700px;
  margin: 35px auto 0 ;
  display: flex;
  align-items: stretch;
  @include max-w($screen-md){
    flex-wrap: wrap;
  }

  &__item{
    width: 50%;
    display: flex;

    @include max-w($screen-md){
      width: 100%;
      margin-bottom: 10px;

      &:last-of-type{
        margin-bottom: 0;
      }
    }

    label{
      display: inline-block;
      font-size: 18px;
      font-weight: 700;
      color: $brand-color;
      margin-bottom: 12px;
    }

    .subtitle {
      font-size: 14px;
      color: #a6a6a2;
    }
  }
}

.radio {
  position: relative;
  width: 34px;
  height: 34px;
  border: 6px solid #cecec8;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  margin-right: 10px;
  flex-shrink: 0;

  .jq-radio__div{
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: $brand-color;
    border-radius: 50%;
    visibility: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.checked{
    .jq-radio__div{
      visibility: visible;
    }
  }

  &--small {
    width: 20px;
    height: 20px;
    border-width: 1px;

    .jq-radio__div {
      width: 10px;
      height: 10px;
    }
  }
}

.invite{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 40px;

  @include max-w($screen-md){
    flex-direction: column;
    align-items: center;
  }

  label{
    display: inline-block;
    max-width:766px;
    width: 100%;
    margin-right: 5px;

    @include max-w($screen-md){
      margin-bottom: 10px;
    }
  }

  .input{
    margin: 0;
  }
}

.select-booking{
  width: 100%;
  background-color: $body-bg;
  font-size: 16px;
  font-weight: 500;
  color: #a6a6a2;

  .jq-selectbox__select{
    display: flex;
    padding: 21px;
    justify-content: space-between;
    align-items: center;
  }

  .jq-selectbox__dropdown{
    background-color: $body-bg;
    width: 100%;
    top: 62px ;
    z-index: 100;

    ul{
      @include clearlist;

      >li{
        margin-bottom: 15px;
        padding: 10px 21px 10px 21px;
        cursor: pointer;

        &:last-of-type{
          margin-bottom: 0;
        }

        &:hover{
          color: $brand-color;
        }

        &.disabled{
          background-color: lightgrey;

          &:hover{
            color: #a6a6a2;
          }
        }
      }
    }
  }

  .jq-selectbox__select-text{
    width: 100%;
  }

  .jq-selectbox__trigger-arrow{
    cursor: pointer;
    width: 16px;
    height: 10px;
    background: url('../images/arrow-down.png') no-repeat center;
  }
}

.step{
  width: 73px;
  height: 100px;
  position: absolute;
  top: 24px;
  left: 24px;
  background: url('../images/step-marker.png') no-repeat center/cover;
  font-size: 18px;
  color: $brand-color;
  font-weight: 700;
  text-align: center;
  padding-top: 27px;

  @include max-w($screen-md) {
    background: url('../images/step-marker.png') no-repeat center/cover;
    background-size: 70%;
    top: 0;
    left: 5px;
    padding-top: 32px;
    font-size: 15px;
  }

  @include max-w($screen-sm) {
    background-size: 55%;
    left: -15px;
    top: -20px;
    font-size: 10px;
    padding-top: 38px;
  }
}

.form-row{
  display: flex;
  align-items: stretch;
  margin-right: -15px;
  margin-left: -15px;

  @include max-w($screen-md){
    flex-wrap: wrap;
  }
}

.col-wr-6{
  display: block;
  width: 50%;
  padding-left: 15px;
  padding-right: 15px;

  @include max-w($screen-md){
    width: 100%;
  }

}


.jq-checkbox{
  position: relative;
  width: 26px;
  height: 26px;
  background-color: #ffffff;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  vertical-align: middle;


  &__div{
    width: 20px;
    height: 20px;
    background-color: transparent;
  }

  &.checked{
    .jq-checkbox__div{
      background-color: $brand-color;
    }
  }
}

.second-pay-adress{
  margin-top: 25px;
  margin-bottom: 25px;

  label{
    vertical-align: middle;
    font-size: 16px;
    color: #a6a6a2;
    letter-spacing: 0.45px;
    font-family: $main-font;
  }
}

.comment{
  resize: none;
  max-width: 455px;
  min-height: 120px;
}

// JS

.hidden-group{
  display: none;
}

.step-2-form,
.step-1-form{
  display: none;
}

.show-step{
  display: block;
}

// form datepicker
.form-datepicker {
  position: relative;

  &__open-modal {
    cursor: pointer;
    position: absolute;
    top: 14px;
    right: 7px;
    z-index: 10;
    width: 56px;
    height: 50px;
    background-image: url('../images/arrow-down.png');
    background-repeat: no-repeat;
    background-position: center;

    @include max-w($screen-sm){
      right: -10px;
    }
  }
}

#date-picker {
  width: 100%;
  max-width: 305px;
}

.date-modal {
  display: none;
  position: absolute;
  top: calc(100% - 10px);
  left: 0;
  right: 0;
  z-index: 10;
  background-color: #fff;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.2);
  color: $brand-color;
  padding: 20px 30px;
  //padding-bottom: 35px;

  @include max-w($screen-md){
    position: static;
  }

  @include max-w($screen-sm){
    padding: 20px 0;
  }

  &--open {
    display: block;
  }

  &__inner {
    display: flex;

    @include max-w($screen-md){
      flex-direction: column;
    }
  }

  &__picker {
    flex: 0 1 50%;
    font-size: 16px;

    .ui-datepicker {
      width: 100%;
    }

    .ui-datepicker-title {
      font-size: 18px;
      font-weight: 700;
      line-height: 1;
      margin-bottom: 10px;
    }

    .ui-datepicker-prev,
    .ui-datepicker-next {
      width: 20px;
      height: 23px;

      .ui-icon {
        width: 10px;
        height: 16px;
        background: url('../images/arrow-right.png') no-repeat center;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }

    .ui-datepicker-prev {
      .ui-icon {
        transform: rotate(180deg);
      }
    }

    th {
      //text-align: left;

      &.ui-datepicker-week-col {
        padding-left: 0;
        text-align: left;
      }

      &:not(.ui-datepicker-week-col) {
        text-transform: lowercase;
      }
    }

    td {

      span,
      a {
        display: inline-block;
        width: 30px;
        height: 30px;
        line-height: 30px;
        padding: 0 6px;
        font-weight: 500;
        text-align: left;
      }

      a {
        color: inherit;
        &:hover {
          background-color: #dbdbd6;
        }

        &.ui-state-active {
          background-color: $brand-color;
          color: #fff;
        }
      }

      &.ui-state-disabled {
        color: #a6a6a2;
      }

      &.ui-datepicker-week-col {
        width: 60px;
      }
    }

    .btn-wrp {
      margin-top: 15px;
    }
  }

  &__time {
    flex: 0 1 50%;
    padding-top: 40px;
    padding-left: 50px;
  }

  &__time-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 14px;
  }

  &__time-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    input {
      flex-shrink: 0;
    }

    label {
      font-size: 16px;
      font-weight: 700;
    }
  }

  &__time-text {
    margin-top: 50px;
    max-width: 250px;
    font-size: 16px;
    color: #a6a6a2;
  }
}
