/*
Icon classes can be used entirely standalone. They are named after their original file names.

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.icon-1 {
  background-image: url(../images/spritesheet.png);
  background-position: -88px -333px;
  width: 40px;
  height: 45px;
}
.icon-1prise {
  background-image: url(../images/spritesheet.png);
  background-position: -392px -129px;
  width: 85px;
  height: 87px;
}
.icon-2 {
  background-image: url(../images/spritesheet.png);
  background-position: -130px -333px;
  width: 40px;
  height: 45px;
}
.icon-2sprite {
  background-image: url(../images/spritesheet.png);
  background-position: -223px -150px;
  width: 79px;
  height: 71px;
}
.icon-3 {
  background-image: url(../images/spritesheet.png);
  background-position: -172px -333px;
  width: 40px;
  height: 45px;
}
.icon-3sprite {
  background-image: url(../images/spritesheet.png);
  background-position: -392px -218px;
  width: 90px;
  height: 79px;
}
.icon-4sprite {
  background-image: url(../images/spritesheet.png);
  background-position: -112px -223px;
  width: 82px;
  height: 72px;
}
.icon-arrow-right-pin {
  background-image: url(../images/spritesheet.png);
  background-position: -291px -297px;
  width: 8px;
  height: 12px;
}
.icon-arrow-right {
  background-image: url(../images/spritesheet.png);
  background-position: -280px -297px;
  width: 9px;
  height: 15px;
}
.icon-basket {
  background-image: url(../images/spritesheet.png);
  background-position: -439px -299px;
  width: 38px;
  height: 30px;
}
.icon-c1 {
  background-image: url(../images/spritesheet.png);
  background-position: -372px -223px;
  width: 18px;
  height: 28px;
}
.icon-c2 {
  background-image: url(../images/spritesheet.png);
  background-position: -262px -333px;
  width: 22px;
  height: 29px;
}
.icon-c3 {
  background-image: url(../images/spritesheet.png);
  background-position: -238px -333px;
  width: 22px;
  height: 29px;
}
.icon-check {
  background-image: url(../images/spritesheet.png);
  background-position: -205px -297px;
  width: 26px;
  height: 20px;
}
.icon-close-c {
  background-image: url(../images/spritesheet.png);
  background-position: -179px -297px;
  width: 24px;
  height: 24px;
}
.icon-close {
  background-image: url(../images/spritesheet.png);
  background-position: -233px -297px;
  width: 20px;
  height: 20px;
}
.icon-crose-del {
  background-image: url(../images/spritesheet.png);
  background-position: -372px -273px;
  width: 13px;
  height: 13px;
}
.icon-fb {
  background-image: url(../images/spritesheet.png);
  background-position: -286px -333px;
  width: 12px;
  height: 19px;
}
.icon-filter {
  background-image: url(../images/spritesheet.png);
  background-position: 0px 0px;
  width: 221px;
  height: 221px;
}
.icon-ic1 {
  background-image: url(../images/spritesheet.png);
  background-position: -214px -333px;
  width: 22px;
  height: 40px;
}
.icon-ic2 {
  background-image: url(../images/spritesheet.png);
  background-position: -392px -299px;
  width: 45px;
  height: 30px;
}
.icon-ic3 {
  background-image: url(../images/spritesheet.png);
  background-position: -112px -297px;
  width: 38px;
  height: 26px;
}
.icon-icon1 {
  background-image: url(../images/spritesheet.png);
  background-position: -284px -223px;
  width: 86px;
  height: 65px;
}
.icon-icon2 {
  background-image: url(../images/spritesheet.png);
  background-position: -196px -223px;
  width: 86px;
  height: 65px;
}
.icon-icon3 {
  background-image: url(../images/spritesheet.png);
  background-position: -304px -150px;
  width: 86px;
  height: 65px;
}
.icon-icon4 {
  background-image: url(../images/spritesheet.png);
  background-position: 0px -333px;
  width: 86px;
  height: 65px;
}
.icon-in {
  background-image: url(../images/spritesheet.png);
  background-position: -372px -253px;
  width: 18px;
  height: 18px;
}
.icon-play {
  background-image: url(../images/spritesheet.png);
  background-position: -392px 0px;
  width: 93px;
  height: 127px;
}
.icon-plus {
  background-image: url(../images/spritesheet.png);
  background-position: -152px -297px;
  width: 25px;
  height: 25px;
}
.icon-snow {
  background-image: url(../images/spritesheet.png);
  background-position: 0px -223px;
  width: 110px;
  height: 108px;
}
.icon-sun {
  background-image: url(../images/spritesheet.png);
  background-position: -223px 0px;
  width: 167px;
  height: 148px;
}
.icon-yt {
  background-image: url(../images/spritesheet.png);
  background-position: -255px -297px;
  width: 23px;
  height: 17px;
}
