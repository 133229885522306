.prismatch-wrp {
  max-width: 1195px;
  padding: 0 15px;
  margin: 0 auto;

  .title {
    font-size: 40px;
    color: $brand-color;

    @include max-w($screen-sm){
      font-size: 30px;
    }
  }

  .description {
    font-size: 16px;
    color: $dark-color;
    max-width: 793px;
  }
}

.prismatch-row {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin: 105px -20px 0;

  @include max-w($screen-xl){
    margin-top: 80px;
  }

  @include max-w($screen-lg){
    margin-top: 50px;
    flex-wrap: wrap;
  }
}

.prismatch-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 25%;
  padding: 54px 20px 39px;

  @include max-w($screen-lg){
    width: 50%;
    margin-bottom: 30px;
  }

  @include max-w($screen-sm){
    width: 100%;
  }

  &:after {
    content: '';
    position: absolute;
    left: 20px;
    right: 20px;
    top: 0;
    bottom: 0;
    background-color: $body-bg;
    z-index: 1;
  }

  .icon-wrp {
    position: relative;
    z-index: 2;
    min-height: 89px;
    margin-bottom: 48px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $dark-color;
    position: relative;
    z-index: 2;
    max-width: 185px;
    font-size: 16px;
    margin: 0 auto;
    text-align: center;
    min-height: 165px;
    background: url('../images/num1.png') no-repeat 45%;

  }

  &:nth-of-type(2){
    .content{
      background: url('../images/num2.png') no-repeat 45%;
    }
  }

  &:nth-of-type(3){
    .content{
      background: url('../images/num3.png') no-repeat 45%;
    }
  }

  &:nth-of-type(4){
    .content{
      background: url('../images/num4.png') no-repeat 45%;
    }
  }
}