.form-row--start {
  align-items: flex-start;

  @include max-w($screen-md){
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

.col-wr-4 {
  display: block;
  width: 33.33%;
  padding-left: 15px;
  padding-right: 15px;

  @include max-w($screen-md){
    width: 100%;
    margin-bottom: 30px;
  }

  &:first-of-type {

    .plan__name {
      background: url('../images/basick.png') no-repeat center;
    }
  }

  &:nth-of-type(2) {

    .plan__name {
      background: url('../images/plus.png') no-repeat center;
      color: $brand-color;
    }
  }

  &:last-of-type {

    .plan__name {
      background: url('../images/prim.png') no-repeat center;
      color: #b31655;
    }

  }
}

.plan {
  background-color: $body-bg;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 40px;
  padding-bottom: 30px;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.07);
  cursor: pointer;
  transition: 0.7s;
  position: relative;
  z-index: 100;

  @include max-w($screen-lg){
    padding-left:5px;
    padding-right: 5px;
  }

  &__name {
    max-width: 175px;
    min-height: 240px;
    margin: 0 auto 26px;
    text-align: center;
    padding-top: 80px;
    font-family: $menu-font;
    color: $dark-color;
    font-size: 18px;
    font-weight: 700;
  }

  &__service {
    @include clearlist;
    min-height: 365px;

    @include max-w($screen-lg){
      min-height: 485px;
    }


    @include max-w($screen-md){
      min-height: inherit;
    }

    > li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid #d5d5d1;
      padding-bottom: 12px;
      padding-top: 12px;

      @include max-w($screen-lg){
        flex-direction: column;
      }

      &:last-of-type {
        border-bottom: none;
      }

      &:first-of-type {
        border-top: 0;
      }

      > i {
        flex-shrink: 0;
        margin-right: 10px;
      }

      > span {
        font-family: $menu-font;
        color: #a6a6a2;
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.5);
    z-index: 101;

    @include max-w($screen-md){
      transform: none;
    }
  }

}

.price-radio {
  display: flex;
  align-items: center;
  max-width: 130px;
  margin-left: auto;

  label {
    font-size: 30px;
    color: $brand-color;
  }
}

.booking-form__title-2--mt-25 {
  margin-top: 25px;
}

.added-btn {
  cursor: pointer;
  text-align: center;
  position: absolute;
  left: 50%;
  bottom: -27px;
  z-index: 2;
  transform: translateX(-50%);

  &__title{
    font-size: 18px;
    font-family: $menu-font;
    color: $brand-color;
    margin-bottom: 12px;
  }

  &__inner {
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: $brand-color;
    position: relative;
    transition: 0.5s;

    &:hover{
      background: $dark-color;
    }

    > i {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
