// Кнопки

.btn-wrp {
  text-align: center;

  &--indent-md{
    margin-top: 120px;
  }

  &--indent-sm{
    margin-top: 100px;

    @include max-w($screen-xl){
      margin-top: 50px;
    }
  }

  &--form{
    margin-top: 28px;
  }

  &--booing{
    margin-top: 50px;
  }

  &--mt-30{
    margin-top: 30px;
  }
}

.btn {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  background: none;
  outline: 0 none;
  border: 0 none;
  border-radius: 0;
  padding: 23px 0;
  width: 320px;
  background-color: $brand-color;
  font-size: 24px;
  color: #fff;
  transition: 0.5s;
  font-weight: 700;

  @include max-w($screen-1440){
    width: 290px;
  }

  @include max-w($screen-lg){
    padding: 22px 0;
    font-size: 20px;
    width: 250px;
  }

  &:hover {
    background-color: darken($brand-color, 5%);
  }

  > .icon-arrow-right {
    position: absolute;
    right: 27px;
    top: 50%;
    transform: translateY(-50%);
  }

  >.icon-basket{
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }

  &--bask{
    text-align:left;
    padding: 17px 0 17px 60px;
    width: 225px;
    font-size: 20px;
    font-family: $menu-font;

    @include max-w($screen-1440){
      width: 215px;
    }

    @include max-w($screen-xl){
      width: 200px;
      font-size: 17px;

      >.icon-arrow-right{
        right: 20px;

      }
    }

    @include max-w($screen-lg){
      width: 30px;
      background-color: inherit;

      &:hover{
        background-color: inherit;
      }
      >.icon-arrow-right,
      >.price,
      >.kurv{
        display: none;
      }
    }
  }

  &--md{
    width: 220px;
    padding: 20px 0;
    text-align: center;

    .icon-arrow-right{
      right: 23px;
    }
  }

  &--form{
    font-family: $menu-font;
    font-weight: 700;

    .icon-arrow-right{
      right: 20px;
    }
  }

  &--sm{
    position: relative;
    width: 134px;
    padding: 18px 0;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    font-family: $menu-font;

    .icon{
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }

    &:first-of-type{
      margin-right: 16px;
    }
  }

  &--pink{
    background-color: #b31655;

    &:hover{
      background-color: darken(#b31655,5%);
    }
  }

  &--heigth-sm{
    width: 274px;
    padding: 18px 0;
    font-family: $menu-font;
    font-weight: 700;
    position: relative;
    z-index: 50;

    @include max-w($screen-xl) {
      width: 250px;
    }
  }

  &--single{
    padding: 18px 0;
    text-align: center;
    width: 180px;

    >.icon-arrow-right{
      right: 15px;
    };
  }

  &__attach{
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    font-family: $menu-font;
    padding: 13px 0;
    width: 212px;

    @include max-w($screen-md){
      margin-top: 20px;
    }

    >.icon{
      right: 10px;
    }
  }

  &--lg{
    width: 350px;
    padding: 18px 0;
    font-size: 22px;
    letter-spacing: 0.56px;
    font-family: $menu-font;
    font-weight: 700;

    @include max-w($screen-sm){
      width: 212px;
      font-size: 16px;
    }

    >.icon{
      right:10px;

      @include max-w($screen-sm){
        display: none;
      }
    }
  }

  &--form-sm{
    width: 200px;
    font-weight: 700;
    padding: 18px 0;

    @include max-w($screen-md){
      width: 135px;

      >.icon{
        display: none;
      }
    }

    @include max-w($screen-sm){
      width: 100px;
    }
  }

  &--indent{
    margin-right: 45px;

    @include max-w($screen-sm) {
      margin-right: 5px;
    }
  }

  &--invite{
    width: 150px;
    padding: 17px 0;
    font-size: 22px;
    font-weight: 700;

    @include max-w($screen-sm){
      width: 100%;

      .icon{
        display: none;
      }
    }

    .icon{
      right: 10px;
    }
  }

  &--popup{
    width: 300px;
    font-family: $menu-font;
    font-size: 24px;
    font-weight: 700;
  }

  &--filter-mobile-btn{
    margin-top: 50px;
    width: 200px;
    display: none;

    @include max-w($screen-lg){
      display: inline-block;
    }
  }
}


.btn-hamburger{
  background-color: transparent;
  outline: none;
  border: none;
  width: 40px;
  padding: 5px 5px;
  margin-right: 15px;
  display: none;
  overflow: hidden;

  &__item{
    display: block;
    width: 100%;
    height: 3px;
    background-color: #fff;
    margin-bottom: 5px;
    transition: 0.5s;
  }

  &.active-burger{

    .btn-hamburger{
      &__item:nth-of-type(2){
        transform: translateX(120%);
      }

      &__item:first-of-type{
        transform: rotate(45deg) translate(11px);
      }

      &__item:last-of-type{

        transform: rotate(-45deg) translate(11px);
      }
    }
  }

  @include max-w($screen-lg){
    display: block;
  }

  @include max-w($screen-md){
    margin-right: 0;
  }
}

.youtube-btn{
  transition: 0.5s;
  &:hover{
    color: $dark-color;
  }
}

.filter-mobile-btn{
  width: 100%;
  background-color: $brand-color;
  text-align: center;
  color: white;
  border:none;
  outline: none;
  margin-right: 5px;
  display: none;
  font-family: $title-font;
  text-transform: uppercase;
  font-weight: 700;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}