.video-wrp {
  position: relative;
  width: 50%;
  padding-top: 225px;
  padding-bottom: 165px;
  z-index: 2;
  background-color: rgba(0, 141, 158, 0.87);

  @include max-w($screen-1440) {
    padding-top: 130px;
    padding-bottom: 130px;
  }

  @include max-w($screen-xl){
    padding-top: 100px;
    padding-bottom: 100px;
  }

  @include max-w($screen-lg){
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.video-section {
  position: relative;
  background: url('../images/bg-video.png') no-repeat;
  background-size: cover;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(0, 45, 78, 0.77);
    z-index: 1;
  }

  &:after {
    content: '';
    display: block;
    background: url('../images/decor-1.png') no-repeat;
    width: 481px;
    height: 636px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;

    @include max-w($screen-xl){
      display: none;
    }
  }
}

.video-info {
  max-width: 510px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .title-info {
    font-size: 60px;
    line-height: 71.55px;
    margin-bottom: 45px;

    @include max-w($screen-xxl) {
      font-size: 55px;
      line-height: 60px;
    }

    @include max-w($screen-1440) {
      font-size: 50px;
      line-height: 60px;
    }

    @include max-w($screen-xl) {
      font-size: 40px;
      line-height: 45px;
      margin-bottom: 30px;
    }

    @include max-w($screen-lg){
      font-size: 35px;
      line-height: 40px;
    }
  }

  .description-info {
    font-size: 30px;

    @include max-w($screen-xxl) {
      font-size: 25px;
    }

    @include max-w($screen-1440) {
      font-size: 23px;
    }

    @include max-w($screen-xl) {
      font-size: 20px;
    }
  }

  > div:first-of-type {
    margin-bottom: 140px;

    @include max-w($screen-1440) {
      margin-bottom: 100px;
    }

    @include max-w($screen-xl) {
      margin-bottom: 50px;
    }
  }
}

.play-title {
  font-family: $title-font;
  font-size: 40px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 15px;

  @include max-w($screen-xl){
    font-size: 30px;
  }
}