//  Подключения шрифтов

// font-family: 'Saltbush Rough';
@font-face {
  font-family: 'SaltbushRough';
  font-weight: normal;
  src:  local('SaltbushRough'), local('SaltbushRough');
  src:  url('../fonts/SB/35C185_2_0.eot');
  src:  url('../fonts/SB/35C185_2_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SB/35C185_2_0.woff2') format('woff2'),
        url('../fonts/SB/35C185_2_0.woff') format('woff'),
        url('../fonts/SB/35C185_2_0.ttf') format('truetype');
}


// font-family: 'Saltbush Rough';
// font-weight: 700;
@font-face {
  font-family: 'SaltbushRough';
  font-weight: 700;
  src:  local('SaltbushRough'), local('SaltbushRough');
  src:  url('../fonts/SB/35C185_1_0.eot');
  src:  url('../fonts/SB/35C185_1_0.eot?#iefix') format('embedded-opentype'),
  url('../fonts/SB/35C185_1_0.woff2') format('woff2'),
  url('../fonts/SB/35C185_1_0.woff') format('woff'),
  url('../fonts/SB/35C185_1_0.ttf') format('truetype');
}

// font-family: 'Heebo';
// font-weight: 700;
@font-face {
  font-family: 'Heebo';
  font-weight: 700;
  src:  local('Heebo'), local('Heebo');
  src:  url('../fonts/Heebo-Bold.eot');
  src:  url('../fonts/Heebo-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Heebo-Bold.woff') format('woff'),
  url('../fonts/Heebo-Bold.ttf') format('truetype');
}

// font-family: 'Heebo';
// font-weight: 500;
@font-face {
  font-family: 'Heebo';
  font-weight: 500;
  src:  local('Heebo'), local('Heebo');
  src:  url('../fonts/Heebo-Medium.eot');
  src:  url('../fonts/Heebo-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Heebo-Medium.woff') format('woff'),
  url('../fonts/Heebo-Medium.ttf') format('truetype');
}

// font-family: 'Heebo';
// font-weight: 400;
@font-face {
  font-family: 'Heebo';
  font-weight: normal;
  src:  local('Heebo'), local('Heebo');
  src:  url('../fonts/Heebo-Regular.eot');
  src:  url('../fonts/Heebo-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Heebo-Regular.woff') format('woff'),
  url('../fonts/Heebo-Regular.ttf') format('truetype');
}

// font-family: 'MyriadHebrew';
// font-weight: 400;
@font-face {
  font-family: 'MyriadHebrew';
  font-weight: normal;
  src:  local('MyriadHebrew'), local('MyriadHebrew');
  src:  url('../fonts/MyriadHebrew-Regular.eot');
  src:  url('../fonts/MyriadHebrew-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/MyriadHebrew-Regular.woff') format('woff'),
  url('../fonts/MyriadHebrew-Regular.ttf') format('truetype');
}


// Заголовки

h1, h2, h3, h4, h5 {
  font-family: $title-font;
}

h1 {
  font-size: 46px;
}

h2 {
	font-size: 46px;
}

h3 {
	font-size: 28px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}
