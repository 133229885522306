//  Цвет

$body-bg: #fff;
$text-color-main: #fff;

$tab-title-color: #002c4d;

$decoration-text-color: #008d9e;

$brand-color: #008d9e;
$second-brand-color: #b31655;

$dark-color: #002d4e;
$second-color: #ecece7;

// Цвет ссылки

$link-color: #fff;
$link-color--hover: #fff;

//  Типографика

//$main-font: 'MyriadHebrew', Helvetica, Arial, sans-serif;
$main-font: 'Heebo',sans-serif;
$title-font: 'SaltbushRough', Helvetica, Arial, sans-serif;
$menu-font: 'Heebo', sans-serif;

$font-size: 25px;
$font-weight: 400; // Regular
$line-height: 1;

// Ширина контейнера

/*$container-padding: 15px;
$container-width: 1170px + $container-padding * 2;*/

//  Адаптивность и соответствующие ширины контейнера

// $screen-xs: 0;
$screen-sm: 480px;
$screen-md: 767px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-1440: 1440px;
$screen-xxl: 1680px;
