// Все элементы формы

input[type='text'],
input[type='email'],
input[type='tel'],
input[type='url'],
input[type='search'],
input[type='password'],
textarea,
select {
  font-family: inherit;
  width: 100%;
  max-width: 100%;
  background: none;
  outline: 0 none;
  border: none;
  padding: 15px 15px 15px 27px;
  color: #adada9;
  background-color: #fff;
  margin-top: 8px;
  margin-bottom: 12px;
  font-size: 16px;
}

button{
  cursor: pointer;
}

.input[type="text"],
textarea{
  padding: 21px 21px;
  font-family: $menu-font;

  &::placeholder {
    font-size: 16px;
    color: #a6a6a2;
    font-weight: 500;
  }

  &:focus {
    &::placeholder {
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  }
}

