//  Базовые стили

html {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background-color: $body-bg;
  color: $text-color-main;
  font-family: $main-font;
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
}

a {
  color: $link-color;
  text-decoration: none;

  &:hover,
  &:active {
    color: $link-color--hover;
  }
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

section {
  position: relative;
}

/*.page-container {
  max-width: $container-width;
  margin-left: auto;
  margin-right: auto;
  padding-left: $container-padding;
  padding-right: $container-padding;
}*/

.icon {
  display: inline-block;
}

.global-wrapper {
  position: relative;

}

.decoration{
  color: $decoration-text-color;
}

.flex-container {
  display: flex;
  flex-direction: row;

  &--justify-space-between {
    justify-content: space-between;
  }

  &--align-cenetr{
    align-items: center;
  }
}

.ancillary-box{
  max-width: 1215px;
  margin: 0 auto;
  background-color: $body-bg;
  padding-top: 64px;
  padding-bottom: 64px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  z-index: 10;

  @include max-w($screen-lg){
    padding-top: 15px;
    padding-bottom: 15px;
  }

  @include max-w($screen-sm){
    max-width: 100%;
  }

  &--md{
    max-width: 910px;
  }
}


.ancillary-content-wrp{
  position: relative;
  max-width: 1090px;
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #dbdbd6;
  padding-left: 15px;
  padding-right: 15px;

  &--md{
    max-width: 805px;
    padding-bottom: 80px;
  }

  &--md-bottom-indent{
    padding-bottom: 55px;
  }

  &--lg-bottom-indent{
    padding-bottom: 95px;
  }

  &--booking{
    &:after{
      position: absolute;
      bottom: -19px;
      left: 50%;
      transform: translateX(-50%);
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 20px 14px 0 14px ;
      border-color: #dbdbd6 transparent transparent transparent;
      z-index: 1;

      @include max-w($screen-md){
        display: none;
      }
    }
  }

  &--margin-indent{
    margin-bottom: 52px;
  }
}


.worn{
  color:#b83062;
  font-size: 16px;
  font-weight: 700;
  font-family: $menu-font;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 15px;

  >a{
    text-decoration: underline;
    color:#b83062;
  }
}

.js-del-btn{
  cursor: pointer;
}

.header-nav.active-menu{
  transform: translateX(0);
}

.visible-tab{
  display: block;
}

.has-filter .global-wrapper{
  overflow: hidden;
}

.js-event-helper{
  position: relative;

  >div{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
}

.js-variation-1,
.js-variation-2{
  display: none;
}