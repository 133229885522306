.page-header {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  z-index: 100;

  @include max-w($screen-lg){
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.logo-wrp{
  max-width: 262px;
  display: block;

  @include max-w($screen-xl){
    max-width: 190px;
  }

  @include max-w($screen-sm) {
    max-width: 150px;
  }

  img{
    width: 100%;
  }
}

.count{
  position: absolute;
  width: 20px;
  height: 20px;
  color: black;
  display: inline-block;
  background-color: $body-bg;
  border-radius: 50%;
  text-align: center;
  z-index: 10;
  left: 5px;
  top: 5px;
  font-size: 12px;
  font-weight: 700;
  vertical-align: middle;
  padding-top: 4px;

  @include max-w($screen-lg){
    left: 7px;
    top: -8px;
  }
}

.price{
  text-align: right;
  margin-left: 35px;
}