.block {
  position: relative;
  max-width: 1225px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

.text-content {
  max-width: 605px;

  .title {
    font-size: 40px;
    color: $brand-color;
    line-height: 47.55px;
    margin-bottom: 20px;
    font-weight: 700;

    @include max-w($screen-xxl){
      font-size: 35px;
      line-height: 40px;
    }

    @include max-w($screen-xxl){
      font-size: 30px;
      line-height: 35px;
    }

  }

  .description,
  .signature-block,
  .additional {
    font-size: 16px;
    color: $dark-color;
    margin-bottom: 80px;
    line-height: 22px;
  }

  .signature-block {
    position: relative;

    @include max-w($screen-xl){
      padding-left: 50px;
    }

    &:before {
      content: '';
      display: block;
      width: 213px;
      height: 88px;
      background: url('../images/signature.png') no-repeat center/cover;
      position: absolute;
      bottom: 15%;
      left: -6%;

      @include max-w($screen-xl){
        left: 0;
      }
    }
  }

  .additional {
    max-width: 414px;
    margin-bottom: 0;
  }

  .pos-img-wrp{
    max-width: 659px;
    position: absolute;
    bottom: -28%;
    right: 0;
    left: auto;

    @include max-w($screen-xl){
      max-width: 550px;
    }

    @include max-w($screen-lg){
      max-width: 380px;
    }

    @include max-w($screen-sm) {
      display: none;
    }
  }
}