.preview-section {
  padding-bottom: 80px;

  @include max-w($screen-lg) {
    padding-bottom: 55px;
  }

  @include max-w($screen-lg) {
    padding-bottom: 40px;
  }

  &--home {
    padding-top: 80px;
  }

  &--daek {
    padding-top: 75px;

    @include max-w($screen-xl){
      padding-top: 40px;
      padding-bottom: 40px;
    }

    @include max-w($screen-lg){
      padding-top: 0;
    }

    .title-wrp {
      position: relative;
      max-width: 1634px;
    }
  }
}

.gray-section {
  position: relative;
  padding-bottom: 110px;
  padding-top: 110px;
  background-color: $second-color;

  @include max-w($screen-xl) {
    padding-top: 90px;
    padding-bottom: 80px;
  }

  @include max-w($screen-lg) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &:before {
    position: absolute;
    right: 0;
    top: -100px;
    content: '';
    display: block;
    width: 606px;
    height: 966px;
    background: url('../images/bg-marker.png') no-repeat;

    @include max-w($screen-lg) {
      display: none;
    }
  }

  &:after {
    overflow: hidden;
    position: absolute;
    left: 0;
    bottom: 0;
    content: '';
    display: block;
    width: 261px;
    height: 721px;
    background: url('../images/bg-marker-2.png') no-repeat 100%;

    @include max-w($screen-lg) {
      display: none;
    }
  }

  &--ceo {
    &:after {
      overflow: hidden;
      position: absolute;
      left: 0;
      bottom: 0;
      content: '';
      display: block;
      width: 245px;
      height: 514px;
      background: url('../images/m-ns.png') no-repeat 100% 0;
    }
  }

  &--erhversaftale {
    overflow: hidden;
    padding-top: 129px;
    padding-bottom: 65px;

    @include max-w($screen-xxl) {
      padding-top: 90px;
    }

    @include max-w($screen-lg) {
      padding-top: 50px;
    }
  }

  &--prismatch {
    padding-top: 129px;
    padding-bottom: 185px;

    @include max-w($screen-xl) {
      padding-top: 100px;
      padding-bottom: 100px;
      overflow: hidden;
    }

    @include max-w($screen-lg){
      padding-top: 70px;
      padding-bottom: 70px;
    }

    &:before{
      @include max-w($screen-xl){
        display: none;
      }
    }
  }

  &--daekskfite {
    padding-top: 129px;
    padding-bottom: 100px;

    @include max-w($screen-1440) {
      padding-top: 90px;
      padding-bottom: 90px;
    }

    @include max-w($screen-md){
      padding-top: 50px;
      padding-bottom: 50px;
    }

    &:after {
      overflow: hidden;
      position: absolute;
      left: 0;
      bottom: 0;
      content: '';
      display: block;
      width: 570px;
      height: 698px;
      background: url('../images/lg-ns.png') no-repeat 100% 0;

      @include max-w($screen-xl) {
        display: none;
      }
    }

    &:before {
      position: absolute;
      right: 0;
      top: -150px;
      content: '';
      display: block;
      width: 523px;
      height: 721px;
      background: url('../images/sm-ns.png') no-repeat right;

      @include max-w($screen-xl) {
        top: -120px;
      }

      @include max-w($screen-lg){
        display: none;
      }
    }
  }

  &--daekside {

    .title {
      text-align: center;
      font-size: 46px;
      font-weight: 700;
      text-transform: uppercase;
      font-family: $title-font;
      color: $decoration-text-color;
      margin-bottom: 60px;

      @include max-w($screen-lg){
        font-size: 40px;
        margin-bottom: 30px;
      }
    }

    &:before {
      display: none;
    }

  }
}

.service {
  max-width: 985px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;

  &__row {
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;

    @include max-w($screen-lg) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &__item {
    width: 50%;
    padding-left: 15px;
    padding-right: 15px;

    @include max-w($screen-lg) {
      width: 80%;

      &:first-of-type {
        margin-bottom: 20px;
      }

    }

    @include max-w($screen-md) {
      width: 100%;
    }

    .title-item {
      font-size: 48px;
      margin-bottom: 25px;

      @include max-w($screen-xxl) {
        font-size: 45px;
      }

      @include max-w($screen-xl) {
        font-size: 40px;
      }

      @include max-w($screen-lg) {
        font-size: 35px;
      }
    }

    .description-item {
      font-size: 25px;
      line-height: 33.08px;
      max-width: 383px;

      @include max-w($screen-xxl) {
        font-size: 22px;
      }

      @include max-w($screen-xl) {
        font-size: 20px;
      }

      @include max-w($screen-xl) {
        font-size: 18px;
      }
    }

    .blue-item,
    .pink-item {
      position: relative;
      padding-top: 30px;
      padding-left: 30px;
      padding-right: 30px;
      min-height: 578px;

      @include max-w($screen-lg) {
        min-height: 510px;
      }
    }

    .blue-item {
      background-color: $brand-color;

      @include max-w($screen-sm) {
        min-height: 420px;
      }
    }

    .pink-item {
      background-color: $second-brand-color;
    }

    .price-block {
      max-width: 412px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -100px;
      margin: auto;

      @include max-w($screen-xl) {
        max-width: 365px;
      }
    }

    .img-price-wrp {
      position: relative;
      .price-mark {
        position: absolute;
        width: 178px;
        height: 248px;
        padding-top: 53px;
        background: url("../images/mark.png") no-repeat center/cover;
        z-index: 20;
        top: -50px;
        right: 0;
        font-size: 37px;
        letter-spacing: -1.09px;
        text-align: center;
        > span {
          font-family: $title-font;
          text-transform: uppercase;
          font-size: 30px;
          font-weight: 700;
        }
      }
    }
  }
}

.car-section {
  text-align: center;
  padding-top: 100px;
  padding-bottom: 80px;
  background: url('../images/bg-car.png') no-repeat center/cover;

  @include max-w($screen-xxl) {
    padding-top: 80px;
  }

  @include max-w($screen-xxl) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .title-section {
    font-size: 89px;
    color: $decoration-text-color;
    line-height: 78.52px;
    font-weight: 400;
    text-transform: none;
    margin-bottom: 45px;

    @include max-w($screen-xxl) {
      font-size: 70px;
      margin-bottom: 35px;
    }

    @include max-w($screen-1440) {
      font-size: 60px;
      line-height: 65px;
      margin-bottom: 30px;
    }

    @include max-w($screen-xl) {
      font-size: 50px;
      line-height: 55px;
      margin-bottom: 25px;
    }

    @include max-w($screen-lg) {
      font-size: 45px;
      line-height: 50px;
      margin-bottom: 20px;
    }

    @include max-w($screen-md) {
      font-size: 35px;
      line-height: 40px;
    }
  }

  .description-section {
    text-transform: uppercase;
    color: $dark-color;
    font-size: 68px;
    font-family: $title-font;
    font-weight: 700;
    margin-bottom: 117px;

    @include max-w($screen-xxl) {
      font-size: 60px;
    }

    @include max-w($screen-1440) {
      font-size: 55px;
      margin-bottom: 70px;
    }

    @include max-w($screen-xl) {
      font-size: 50px;
      margin-bottom: 60px;

    }

    @include max-w($screen-lg) {
      font-size: 40px;
      margin-bottom: 50px;

    }

    @include max-w($screen-md) {
      font-size: 35px;
    }

  }
}

.wrapper-car {
  max-width: 688px;
  margin: 0 auto 25px;

  @include max-w($screen-xl) {
    max-width: 500px;
  }

  @include max-w($screen-md) {
    max-width: 250px;
  }

  img {
    width: 100%;
  }
}

.weather-icon {
  .icon {
    vertical-align: middle;
    margin-top: 35px;
  }
}

.title-wrp {
  max-width: 845px;
  margin: 0 auto;

  &--lg {
    max-width: 1220px;
    margin-top: 88px;

    @include max-w($screen-md) {
      margin-top: 60px;
    }
  }
}

.title-section {
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  color: #002d4e;

  @include max-w($screen-xxl) {
    font-size: 42px;
  }

  @include max-w($screen-1440) {
    font-size: 38px;
  }

  @include max-w($screen-xl) {
    font-size: 33px;
  }

  @include max-w($screen-lg) {
    font-size: 30px;
  }

  @include max-w($screen-sm) {
    font-size: 24px;
    padding: 0 15px;
  }
}

.weather-icon {
  @include max-w($screen-sm) {
    display: none;
  }
}