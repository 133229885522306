.filter-wrp {
  position: absolute;
  left: 50%;
  bottom: -110px;
  transform: translateX(-50%);
  max-width: 1664px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.15);
  padding-top: 30px;
  padding-bottom: 50px;
  background-color: $body-bg;
  z-index: 10;

  @include max-w($screen-1440){
    bottom: -90px;
  }

  @include max-w($screen-lg) {
    position: fixed;
    top: 0;
    max-height: 100vh;
    width: 50vw;
    z-index: 1000;
    right: 0;
    left: 0;
    transform: translateX(-100%);
    overflow: auto;
    transition: 0.5s;
  }

  @include max-w($screen-md) {
    width: 100vw;
  }

  .title-filter {
    font-family: $title-font;
    font-size: 28px;
    text-transform: uppercase;
    color: $dark-color;
    line-height: 64.55px;
    letter-spacing: 0.78px;
    text-align: center;
    margin-bottom: 10px;
  }
}

.filter {
  max-width: 1400px;
  margin: 0 auto;

  label {
    display: block;
    color: #a6a6a2;
    font-size: 16px;
    font-weight: 500;
    font-family: $menu-font;
  }
}

.filter-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  @include max-w($screen-lg) {
    flex-direction: column;
  }

}

.first-group {
  position: relative;
  max-width: 450px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 0 -15px;

  @include max-w($screen-lg) {
    flex-direction: column;
    max-width: 100%;
    margin: 0;
  }

  &:after {
    position: absolute;
    content: '';
    display: block;
    width: 645px;
    height: 140px;
    background: url('../images/nyt-daek.png') no-repeat;
    top: 54%;
    left: -107px;

    @include max-w($screen-lg) {
      display: none;
    }
  }

  > div {
    width: 33.33%;
    padding-left: 15px;
    padding-right: 15px;

    @include max-w($screen-lg){
      width: 100%;
      padding: 0;
      margin-bottom: 15px;
    }
  }
}

.second-group {
  max-width: 760px;
  width: 100%;

  @include max-w($screen-xl) {
    max-width: 490px;
  }

  @include max-w($screen-md){
    max-width: 100%;
  }

  .row {
    display: flex;
    margin: 0 -15px;

    @include max-w($screen-md){
      flex-wrap: wrap;
    }

    > div {
      width: 50%;
      padding-left: 15px;
      padding-right: 15px;

      @include max-w($screen-md){
        width: 100%;
        margin-bottom: 20px;
      }
    }

    &.first-row {
      margin-bottom: 25px;
    }
  }
}

.filter-select {
  width: 100%;
  background-color: #ecece7;
  margin-top: 5px;
  font-size: 16px;
  color: #a6a6a2;


  .jq-selectbox__select {
    padding: 23px 19px 23px 17px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .jq-selectbox__select-text {
    font-weight: 500;
    font-family: $menu-font;
  }

  .jq-selectbox__trigger-arrow {
    width: 16px;
    height: 11px;
    background: url('../images/arrow-btn.png') no-repeat center;
  }

  .jq-selectbox__dropdown {
    border: 1px solid lightgrey;
    width: 100%;
    transform: translateY(4px);

    > ul {
      margin: 0;
      padding: 0;

      > li {
        text-align: left;
        padding: 15px;
        border-bottom: 1px solid lightgrey;
        background-color: #ecece7;
        cursor: pointer;
        transition: 0.5s;

        &:last-of-type {
          border: none;
        }

        &:hover {
          color: white;
          background: darken(#ecece7, 10%);
        }

        &.disabled {
          background: darken(#ecece7, 10%);

          &:hover {
            color: #a6a6a2;
          }
        }
      }
    }
  }
}

.dots {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    z-index: 5;
  }

  &--left {
    &:after {
      top: 45%;
      left: 50px;
      width: 49px;
      height: 128px;
      background: url('../images/dl.png') no-repeat;

      @include max-w($screen-lg) {
        display: none;
      }
    }
  }

  &--center {
    &:after {
      top: 45%;
      left: 70px;
      width: 7px;
      height: 64px;
      background: url('../images/dc.png') no-repeat;

      @include max-w($screen-lg) {
        display: none;
      }
    }
  }

  &--right {
    &:after {
      top: 45%;
      right: 50px;
      width: 58px;
      height: 132px;
      background: url('../images/dr.png') no-repeat;

      @include max-w($screen-lg) {
        display: none;
      }
    }
  }
}

//Sort select

.sort {
  width: 278px;
  position: absolute;
  right: -7px;
  bottom: 0;

  @include max-w($screen-xxl){
    right: 6px;
  }

  @include max-w($screen-xl) {
    position: relative;
    z-index: 99;
    margin: 0 auto;
    right: 0;
  }

  .jq-selectbox__select {

    .jq-selectbox__select-text {
      font-weight: 700;
      font-size: 24px;
      font-family: $menu-font;
    }
  }

}

.show-filter{
  transform: translateX(0);
}