.tab-wrp {
  max-width: 705px;
  margin: 0 auto;
  width: 100%;
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;

  @include max-w($screen-xl){
    max-width: 600px;
  }

  @include max-w($screen-lg){
    max-width: 500px;
  }
}

.tabs-content-box {
  background-color: $body-bg;
  padding-bottom: 34px;
  padding-top: 40px;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.15);

  @include max-w($screen-lg){
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.tab-content-tab-title {
  font-family: $title-font;
  font-size: 28px;
  letter-spacing: 0.78px;
  line-height: 64.55px;
  text-transform: uppercase;
  color: $tab-title-color;
  text-align: center;
}

.tabs-control {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;

  &__item {
    width: 50%;

    > a {
      display: block;
      background-color: #ecece7;
      color: $decoration-text-color;
      font-size: 30px;
      text-align: center;
      padding-top: 15px;
      padding-bottom: 10px;
      box-shadow: inset 0 0 18px rgba(0, 0, 0, 0.15);
      transition: 0.5s;

      @include max-w($screen-xxl){
        font-size: 28px;
      }

      @include max-w($screen-1440){
        font-size: 25px;
      }

      @include max-w($screen-lg){
        font-size: 20px;
      }

      &.active-tab {
        background-color: $body-bg;
        box-shadow: none;
      }
    }
  }
}

.tab-content {
  display: none;

  &.active-content {
    display: block;
  }
}